import './_menu';

{
  const init = () => {
    console.log('js loaded');

    AOS.init({
      disable: 'phone',
      easing: 'ease-in-out',
      duration: '650',
    });
  };

  init();
}
