{

  const iconButton = document.querySelector(".nav__icon");

  const handleToggleIcon = () => {
    const icons = Array.from(iconButton.querySelectorAll(".nav__icon__svg"));
    icons.forEach(i => i.classList.toggle('hidden'));
  }

  const handleToggleNavigation = () => {
    console.log('wuk');
    document.querySelector('.nav').classList.toggle('nav--active');
  }

  const init = () => {
    iconButton?.addEventListener(`click`, handleToggleIcon);
    iconButton?.addEventListener(`click`, handleToggleNavigation);
  };

  init();
}